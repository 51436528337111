import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';
import { PostCompanyDashboard } from '../companydashboard/companydashboardmodel';
import { PostMovie } from '../companydashboard/companydashboardmodel';
import { PostConcession } from '../companydashboard/companydashboardmodel';

@Injectable({
  providedIn: 'root'
})

export class CompanyAdminService extends ApiService {
  ApiBaseUrl: string;
  ApiV2BaseUrl: string;
  BaseUri: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/Company';
    this.ApiV2BaseUrl = baseUrl + 'api/V2/Company';
    this.BaseUri = baseUrl;
  }
  GetCompanyAdmins(): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + '/GetCompanyAdmins')
      .pipe(catchError(this.errorHandler));
  }
  UpdateCompanyAdmins(selEmployees: any): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/UpdateCompanyAdmins', selEmployees)
      .pipe(catchError(this.errorHandler));
  }
  GetCompanyDashboard(PCD: PostCompanyDashboard): Observable<any> {
    return this.http.post(this.ApiBaseUrl +"/CompanyDashboardReportBeta", PCD)
      .pipe(catchError(this.errorHandler));
  }
  GetMovieSummaryGrid(PCD: PostMovie): Observable<any> {
    return this.http.post(this.ApiBaseUrl + "/MovieSummaryGridBeta", PCD)
      .pipe(catchError(this.errorHandler));
  }
  GetCompanyDashboardV2(PCD: PostCompanyDashboard): Observable<any> {
    return this.http.post(this.ApiV2BaseUrl + "/CompanyDashboardReport", PCD)
      .pipe(catchError(this.errorHandler));
  }
  GetMovieSummaryGridV2(PCD: PostMovie): Observable<any> {
    return this.http.post(this.ApiV2BaseUrl + "/MovieSummaryGrid", PCD)
      .pipe(catchError(this.errorHandler));
  }
  GetConcessionSummaryGrid(PCD: PostConcession): Observable<any> {
    return this.http.post(this.ApiBaseUrl + "/ConcessionSummaryGrid", PCD)
      .pipe(catchError(this.errorHandler));
  }
  GetRefundSummaryGrid(PCD: PostCompanyDashboard): Observable<any> {
    return this.http.post(this.ApiBaseUrl +"/RefundSummaryGrid", PCD)
      .pipe(catchError(this.errorHandler));
  }
}
