import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from './company';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';
import { LocationsService } from '../locations/locations.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ApiService {
  Company_ID:any
  ApiBaseUrl: string;
  companyLogoUrl: string = "assets/landing/assets/img/noimgport.png";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private locationService: LocationsService,private router:Router) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/Company';
  }
  
  getCompanies(): Observable<Array<Company>> {
    return this.http.get<Array<Company>>(this.ApiBaseUrl)
      .pipe(catchError(this.errorHandler));
  }
  getCompany(): Observable<Company> {
    return this.http.get<Company>(this.ApiBaseUrl +"/GetCompanyByCompanyId")
      .pipe(catchError(this.errorHandler));
  }
  editCompany(company: Company): Observable<any> {
    return this.http.put(this.ApiBaseUrl + "/" + company.Company_ID, company)
      .pipe(catchError(this.errorHandler));
  }
  getCompanyIdByContext(): Observable<Company> {
  return this.http.get<Company>(this.ApiBaseUrl + "/GetCompanyIdByContext")
      .pipe(catchError(this.errorHandler));
  }

  registerCompany(newClientCompany: Company): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl, newClientCompany)
      .pipe(catchError(this.errorHandler));
  }

  getCompanyLogoUrl() {
    this.getCompanyIdByContext().subscribe((res) => {
      this.Company_ID = res;
      if (this.Company_ID) {
        this.locationService.getCompanyByCompanyId(this.Company_ID).subscribe((company) => {
          if (company && company.LogoImage)
            this.companyLogoUrl = company.LogoImage;
          var locationDetail = JSON.parse(sessionStorage.getItem("location"));
          if (locationDetail) {
            if (locationDetail.LogoImage && locationDetail.LogoImage != "") {
              this.companyLogoUrl = locationDetail.LogoImage;
            }
          }
        });
      } else {
        this.router.navigate(['/']).then(() => {
          window.location.reload();
         
        })
      }
    })
  }

  checkEmailIdExistsToAnotherCompany(emailId): Observable<Company> {
    return this.http.get<Company>(this.ApiBaseUrl + "/CheckEmailIdExistsToAnotherCompany/" + emailId)
      .pipe(catchError(this.errorHandler));
  }
  getWikiLink(): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + "/GetWikiLink")
      .pipe(catchError(this.errorHandler));
  }
  getsupportinfo(): Observable<any> {
    return this.http.get<any>(this.BaseUrl + "api/Support/GetAllSupport")
      .pipe(catchError(this.errorHandler));
  }
  genrateAuthorizeExternalDevicePin(authorizeaccesspin: any): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + "/GenrateAuthorizeExternalDevicePin", authorizeaccesspin)
      .pipe(catchError(this.errorHandler));
  }
  GetPaymentOptions(): Observable<any> {
    return this.http.get(this.ApiBaseUrl + "/GetPaymentOptions")
      .pipe(catchError(this.errorHandler));
  }
}
