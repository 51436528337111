import { SalesChannelBase } from "../helper/models/SalesChannelBase";
import { ParentItem } from "../helper/models/ParentItem";
import { LocationBase } from "../helper/models/LocationBase";
import { FoodItem } from "../food/foodItem";
import { Inventory } from "../inventory/inventory";
import { StorageRoom } from "../inventory/storageRoom";

export class Concession extends LocationBase {
  ConcessionId: string;
  ConcessionName: string;
  Description: string;
  ConcessionCategory: string = '';
  ConcessionSubCategory: string = '';
  ConcessionCategoryID: string = '';
  ConcessionSubCategoryID: string = '';
  IsDigitalDisplay: boolean;  // as discussed with mitwa new requirement for online concession display 20-07-21
  DigitalDisplayImageUrl: string;  // as discussed with mitwa new requirement for online concession display 20-07-21
  TaxName: string;
  TaxId: string;
  Tax: number = 0;
  SalesChannel: Array<SalesChannelBase>;
  TotalPrice: number;
  DisplayOrder: number;
  ImageURL: string;
  Quantity: number;
  TotalInventoryQty: number;
  PreferredStockLevel: number;
  LowInventoryCount: number;
  Combo: string;
  Complimentary: boolean;
  SeatNumbers: string;
  ScreenNumber: string;
  ScreeId: string;
  ParentType: ParentItem = new ParentItem();
  PrepAreaId: string;
  PrepAreaName: string;
  LoyaltyPoints: string = '0';
  IsParent: boolean;
  ParentId: string;
  IsCustomize: boolean;
  IsAgeRestrict: boolean = false;
  static getCombo() {
    return [
      { name: "Yes", value: "true" },
      { name: "No", value: "false" }
    ];
  }
  static getCategory() {
    return [
      { name: "Food", value: "food" },
      { name: "Drink", value: "drink" },
      { name: "Snack", value: "snack" },
      { name: "Combo", value: "combo" },
      { name: "Add-on", value: "addon" }
    ];
  }
  static getfoodSubCategory() {
    return [
      { name: 'Appetizer', value: "appetizer" },
      { name: 'MainCourse', value: "maincourse" },
      { name: 'Desert', value: "desert" },
      { name: 'Kids', value: "kids" },
      { name: 'Soup/Salad', value: "soupssalads" }
    ]
  }
  static getdrinksSubCategory() {
    return [
      { label: 'Alcohol', value: 'alcohol' },
      { label: 'NonAlcohol', value: 'nonalcohol' }
    ]
  }
  static getFilteredSubCategory() {
    return [
      { name: "Snack", value: "snack" },
      { name: "Combo", value: "combo" }
    ];
  }

}
export class ConcessionFoodItem extends FoodItem {
  Selected: boolean;
  Price: number;
  Quantity: number;
  Cooked: boolean = false;
  constructor(foodItem: FoodItem, selected: boolean) {
    super();
    this.Selected = selected;
    this.CreatedBy = foodItem.CreatedBy;
    this.CreatedOn = foodItem.CreatedOn;
    this.Description = foodItem.Description;
    this.FoodItemId = foodItem.FoodItemId;
    this.FoodItemName = foodItem.FoodItemName;
    this.LocationId = foodItem.LocationId;
    this.LocationName = foodItem.LocationName;
    this.Media = foodItem.Media;
    this.Price = foodItem.Price;
    this.UpdatedBy = foodItem.UpdatedBy;
    this.UpdatedOn = foodItem.UpdatedOn;
    this.Visible = foodItem.Visible;
    this.Cooked = false;
  }
}

export class ConcessionInventory extends Inventory {
  Selected: boolean;
  Price: number;
  Quantity: number;
  Cooked: boolean=false;
  constructor(inventory: any, selected: boolean) {
    super();
    this.CreatedBy = inventory.CreatedBy;
    this.CreatedOn = inventory.CreatedOn;
    this.Description = inventory.Description;
    this.ExpireDate = inventory.ExpireDate;
    this.InventoryId = inventory.InventoryId;
    this.ItemImage = inventory.ItemImage;
    this.LocationId = inventory.LocationId;
    this.Selected = selected;
    this.Sellable = inventory.Sellable;
    this.Title = inventory.Title;
    this.TotalItemsInCase = inventory.TotalItemsInCase;
    this.TotalQuantityInHand = inventory.TotalQuantityInHand;
    this.UOM = inventory.UOM;
    this.StorageRoom = new StorageRoom;
    this.PreferredStockLevel = inventory.PreferredStockLevel;
    this.LowInventoryCount = inventory.LowInventoryCount;
    this.TotalSold = inventory.TotalSold;
    this.UpdatedBy = inventory.UpdatedBy;
    this.UpdatedOn = inventory.UpdatedOn;
    this.Visible = inventory.Visible;
    this.Cooked = false;

  }
}

export class ConcessionCategory {
  name: String = '';
  value: String = '';
  DisplayName: String = '';
  SubCategory: ConcessionSubCategory[] = [];
  SubCategorystring:any='';
  LocationId:string="";
  LocationName:string="";
  DisplayOrder:number;
  FoodCategoryId:string;
  Visible:number;
}
export class ConcessionSubCategory {
  name: String = '';
  value: String = '';
  DisplayName: String = '';
  ParentCategoryValue: String = '';
  SubCategoryID: string = '';
  DisplayOrder:number;
}

// export class ConcessionCategory  {
//   ConcessionCategoryId: string = '';
//   CuisineId: string = '';
//   Name: string = '';
//   Value: string = '';
//   CategoryType: string = '';
//   BackgroundColor: string = '';
//   ImageURL: string = '';
//   DisplayName: string = '';
//   DisplayOrder: number = 0;
//   SubCategory: ConcessionSubCategory[]=[];
// }
// export class ConcessionSubCategory {
//   ConcessionSubCategoryId: string = '';
//   Name: string = '';
//   Value: string = '';
//   ImageURL: string = '';
//   DisplayName: string = '';
//   Description: string = '';
// }

export class ConcessionCustomize extends LocationBase {
  ConcessionCustomizeId: string;
  ConcessionId: string; 
  CustomizeSize: Array<CustomizeSize> = new Array<CustomizeSize>();
  Ingredients: Array<Ingredients> = new Array<Ingredients>();
}

export class CustomizeSize {
  SizeId: string;
  Price: number;
  IsDisabled?: boolean; 
}

export class Ingredients {
  FoodId: string;
  Name: string;
  Price: number;
  Quantity: number;
  InventoryId: string;
  IsRemovable: boolean;
  IsSubstitute: boolean;
  Substitutes: Array<Substitutes> = new Array<Substitutes>();
}

export class Substitutes {
  Name: string;
  FoodId: string;
  InventoryId: string;
  StorageId: string;
  UOM: string;
  Price: number;
  Quantity: number;
  newUOMActualQty?: any;
  StorageRoom?: any;
  UnitOfMeasure?: any;
  Description: string;
  ToCook: boolean = false;
}

export class ConcessionSize {
  SizeId: string;
  SizeName: string;
}

export interface ProcessInventorySoldOutRequest {
  ConcessionId: string;         
  InventoryIds: string[];       
  CustomInventoryIds : string[];
}