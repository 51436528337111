import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { SalesOrder } from "../helper/models/SalesOrder";
import { ApiService } from "../helper/services/api.service";
import { Inventory, InvetorySnapshot } from "../inventory/inventory";
import { LocationMovie } from "../location-movie/location-movie";
import { DistributorReportInput } from "../helper/models/DistributorReportInput";
import { DistributorReportOutput } from "../helper/models/DistributorReportOutput";
import { MovScheduleReportInput } from "./report-movie-schedule/report-movie-schedule.component";
import { ShiftReportInput, ShiftReportOutput } from "./report-shift/report-shift.component";
import { ConcessionReportInput } from "./report-concession/report-concession.component";
import { MovieScheduleByDateForReports } from "./MovieScheduleReportByDate";
import { MovAttendanceReportInput } from "./report-movie-attendance/report-movie-attendance.component";
import { OnlineReportInput, OnlineReportOutput } from "./report-online/report-online.component";
import { DailySalesReportInput } from "../helper/models/DailySalesReportInput";
import { DailySalesReportOutput } from "../helper/models/DailySalesReportOutput";
import { InventoryRealTimeReportInput } from "../helper/models/InventoryRealTimeReportInput";
import { InventoryHistory } from "../helper/models/InventoryHistory";
import { BoxOfficeReportOutput } from "../helper/models/BoxOfficeReportOutput";
import { BoxOfficeReportInput } from "../helper/models/BoxOfficeReportInput";
import { TransactionReportInput } from "../helper/models/TransactionReportInput";
import { TransactionReportOutput } from "../helper/models/TransactionReportOutput";
import { EmployeeScheduleReportInput } from "./report-employeeschedule/report-employeeschedule.component";
import { EmployeeScheduleReportByDateOutput, EmployeeScheduleReportOutput } from "./report-employeeschedule/EmployeeScheduleReportByDateOutput";
import { InventoryVarienceReportOutPut } from "../helper/models/InventoryVarienceReportOutPut";
import { ReceivablesReportInput } from "../helper/models/ReceivablesReportInput";
import { ReceivablesReportOutput } from "../helper/models/ReceivablesReportOutput";
import { Receivables } from "../inventory/receivables";
import { IncompleteOrderReportInput } from "../helper/models/IncompleteorderModel";
import { SummaryReportInput } from "../helper/models/SummaryReportInput";
import { SummaryReportOutput } from "../helper/models/SummaryReportOutput";
import { ConcessionCategory, ConcessionSubCategory } from "../concession/concession";
import { InventorySellvsPurchaseInput } from "../helper/models/InventorySellvsPurchaseInput";
import { InventorySellvsPurchaseOutput } from "../helper/models/InventorySellvsPurchaseOutput";

import { ShiftSummaryReportInput, ShiftSummaryReportOutput } from "./report-shiftsummary/report-shiftsummary.component";
import { ShiftSummaryOutput } from "../helper/models/ShiftSummaryOutput";
import { SalesDiscountReportInput } from "../helper/models/SalesDiscountReport";
import { TipsReportInput } from "./tips-report/TipsReport";
import { Movie } from "../movies/movie";
@Injectable({
  providedIn: "root"
})

export class ReportsService extends ApiService {
  ApiBaseUrl: string;
  ApiV2BaseUrl: string;
  ApiUrl: string;
  salesOrder: SalesOrder[] = [];
  paymentMode = [
    {
      PaymentName: "CreditCard",
      PaymentMode: 0
    },
    {
      PaymentName: "Cash",
      PaymentMode: 1
    },
    {
      PaymentName: "GiftCard",
      PaymentMode: 3
    },
  ]
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + "api/Reports";
    this.ApiV2BaseUrl = baseUrl + "api/V2/Reports";
    this.ApiUrl = baseUrl + "api";
  }
  GetPayment() {
    return this.paymentMode
  }

  GetPaymentOptions() {

    console.log('the url is ', this.ApiBaseUrl + "/" + "GetPaymentOptions")
    return this.http.get<any>(this.ApiBaseUrl + "/" + "GetPaymentOptions")
      .pipe(catchError(this.errorHandler));
  }

  GetPaymentOptionsForReport() {

    console.log('the url is ', this.ApiBaseUrl + "/" + "GetPaymentOptionsForReport")
    return this.http.get<any>(this.ApiBaseUrl + "/" + "GetPaymentOptionsForReport")
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryReport(): Observable<Array<Inventory>> {
    return this.http.get<Array<Inventory>>(this.ApiBaseUrl + '/GetInventoryReport')
      .pipe(catchError(this.errorHandler));
  }

  GetMovies(Distributor: string): Observable<Array<LocationMovie>> {
    return this.http.get<Array<LocationMovie>>(this.ApiBaseUrl + '/GetMoviesByDistributor?distributor=' + Distributor)
      .pipe(catchError(this.errorHandler));
  }

  GetMoviesByAllDistributors(Distributors: string[]): Observable<Array<LocationMovie>> {
    return this.http.post<Array<LocationMovie>>(this.ApiBaseUrl + '/GetAllMoviesByDistributors', Distributors)
      .pipe(catchError(this.errorHandler));
  }

  GetSubCategoriesByCategoryValue(categoryValue: string): Observable<Array<ConcessionSubCategory>> {
    return this.http.get<Array<ConcessionSubCategory>>(this.ApiBaseUrl + '/GetSubCategoriesByCategoryValue?categoryValue=' + categoryValue)
      .pipe(catchError(this.errorHandler));
  }

  GetSubCategoriesByAllCategoryValues(categoryValues: string[]): Observable<Array<ConcessionSubCategory>> {
    return this.http.post<Array<ConcessionSubCategory>>(this.ApiBaseUrl + '/GetSubCategoriesByAllCategoryValues', categoryValues)
      .pipe(catchError(this.errorHandler));
  }

  GetViewDetails(Confirmationcode: string): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.ApiUrl + '/SalesOrder/' + Confirmationcode.replace('R-', ''))
      .pipe(catchError(this.errorHandler));
  }

  GetDistributorReport(data: DistributorReportInput): Observable<Array<DistributorReportOutput>> {
    return this.http.post<Array<DistributorReportOutput>>(this.ApiBaseUrl + '/GetDistributorReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetDistributorReportV2(data: DistributorReportInput): Observable<Array<DistributorReportOutput>> {
    return this.http.post<Array<DistributorReportOutput>>(this.ApiV2BaseUrl + '/GetDistributorReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetIncompleteOrderReport(data: IncompleteOrderReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetIncompleteOrderReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetIncompleteOrderDetail(data: IncompleteOrderReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetIncompleteOrderDetail', data)
      .pipe(catchError(this.errorHandler));
  }

  GetSalesDiscountReport(data: SalesDiscountReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetSalesDiscountReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetBoxOfficeReport(data: BoxOfficeReportInput): Observable<Array<BoxOfficeReportOutput>> {
    return this.http.post<Array<BoxOfficeReportOutput>>(this.ApiBaseUrl + '/GetBoxOfficeReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetBoxOfficeReportV2(data: BoxOfficeReportInput): Observable<Array<BoxOfficeReportOutput>> {
    return this.http.post<Array<BoxOfficeReportOutput>>(this.ApiV2BaseUrl + '/GetBoxOfficeReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetTransactionReport(data: TransactionReportInput): Observable<Array<TransactionReportOutput>> {
    return this.http.post<Array<TransactionReportOutput>>(this.ApiBaseUrl + '/GetTransactionReport', data)
      .pipe(catchError(this.errorHandler));
  }

  getSalesOrder(): Observable<SalesOrder[]> {
    return this.http
      .get<SalesOrder[]>(this.ApiBaseUrl)
      .pipe(catchError(this.errorHandler));
  }

  GetMovScheduleReport(data: MovScheduleReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetMovScheduleReport', data)
      .pipe(catchError(this.errorHandler));
  }
  GetSalesOrderbyDate(data: MovScheduleReportInput): Observable<Array<SalesOrder>> {
    return this.http.post<Array<SalesOrder>>(this.ApiBaseUrl + '/GetSalesOrderbyDate', data)
      .pipe(catchError(this.errorHandler));
  }

  GetSalesConsolidatedbyDate(data: MovScheduleReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetSalesConsolidatedbyDate', data)
      .pipe(catchError(this.errorHandler));
  }

  GetMovScheduleReportByDate(data: MovScheduleReportInput): Observable<Array<MovieScheduleByDateForReports>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetMovScheduleReportByDate', data)
      .pipe(catchError(this.errorHandler));
  }

  GetMovAttendanceReport(data: MovAttendanceReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetMovAttendanceReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetMovAttendanceReportV2(data: MovAttendanceReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiV2BaseUrl + '/GetMovieAttendanceReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetShiftReport(data: ShiftReportInput): Observable<Array<ShiftReportOutput>> {
    return this.http.post<Array<ShiftReportOutput>>(this.ApiBaseUrl + '/GetShiftReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetOnlineReport(data: OnlineReportInput): Observable<Array<OnlineReportOutput>> {
    return this.http.post<Array<OnlineReportOutput>>(this.ApiBaseUrl + '/GetOnlineReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetConcessionReport(data: ConcessionReportInput): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetConcessionReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetConcessionReportV2(data: ConcessionReportInput): Observable<any> {
    return this.http.post<any>(this.ApiV2BaseUrl + '/GetConcessionReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetDailySalesReport(data: DailySalesReportInput): Observable<DailySalesReportOutput> {
    return this.http.post<DailySalesReportOutput>(this.ApiBaseUrl + '/GetDailySalesReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetDailySalesReportV2(data: DailySalesReportInput): Observable<DailySalesReportOutput> {
    return this.http.post<DailySalesReportOutput>(this.ApiV2BaseUrl + '/GetDailySalesReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetDailyPerformanceReport(data: DailySalesReportInput): Observable<DailySalesReportOutput> {
    return this.http.post<DailySalesReportOutput>(this.ApiBaseUrl + '/GetDailyPerformanceReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryRealTimeReport(data: InventoryRealTimeReportInput): Observable<Array<InventoryHistory>> {
    return this.http.post<Array<InventoryHistory>>(this.ApiBaseUrl + '/GetInventoryRealTimeReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryHistoryReport(data: InventoryRealTimeReportInput): Observable<Array<InventoryVarienceReportOutPut>> {
    return this.http.post<Array<InventoryVarienceReportOutPut>>(this.ApiBaseUrl + '/GetInventoryHistoryDateRangeVarianceReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetEmpScheduleReportByEmployeeName(data: EmployeeScheduleReportInput): Observable<Array<EmployeeScheduleReportOutput>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetEmpScheduleReportByEmployeeName', data)
      .pipe(catchError(this.errorHandler));
  }

  GetEmpScheduleReport(data: EmployeeScheduleReportInput): Observable<Array<EmployeeScheduleReportByDateOutput>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetEmpScheduleReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetSeatNumberByShowId(data: any) {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetSeatNumberByShowIdfromSalesOrder', data)
  }

  GetSalesOrdersByShowIdAndSeatNumber(data: any) {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetSalesOrdersByShowIdAndSeatNumber', data)
  }

  GetReceivablesReport(data: ReceivablesReportInput): Observable<Array<ReceivablesReportOutput>> {
    return this.http.post<Array<ReceivablesReportOutput>>(this.ApiBaseUrl + '/GetReceivablesReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetInventorySnapShotByLocationId(data: InventoryRealTimeReportInput): Observable<Array<InvetorySnapshot>> {
    return this.http.post<Array<InvetorySnapshot>>(this.ApiBaseUrl + '/GetInventorySnapShotByLocationId', data)
      .pipe(catchError(this.errorHandler));
  }

  GetSummaryReport(data: SummaryReportInput): Observable<SummaryReportOutput> {
    return this.http.post<SummaryReportOutput>(this.ApiBaseUrl + '/GetSummaryReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetSummaryReportV2(data: SummaryReportInput): Observable<SummaryReportOutput> {
    return this.http.post<SummaryReportOutput>(this.ApiV2BaseUrl + '/GetSummaryReport', data)
      .pipe(catchError(this.errorHandler));
  }

  getInventorysellvspurchasereport(data: InventorySellvsPurchaseInput): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetInventorySellvsPurchase', data)
      .pipe(catchError(this.errorHandler));
  }

  GetShiftSummaryReport(data: ShiftSummaryReportInput): Observable<Array<ShiftSummaryReportOutput>> {
    return this.http.post<Array<ShiftSummaryReportOutput>>(this.ApiBaseUrl + '/GetShiftSummaryReport', data)
      .pipe(catchError(this.errorHandler));
  }

  ShiftSummary(data: ShiftSummaryReportInput): Observable<ShiftSummaryOutput> {
    return this.http.post<ShiftSummaryOutput>(this.ApiBaseUrl + '/ShiftSummary', data)
      .pipe(catchError(this.errorHandler));
  }

  GetTipsReport(data): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetTipsReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetAllMoviesByIds(names: string[]): Observable<Array<Movie>> {
    return this.http.post<Array<Movie>>(this.ApiBaseUrl + '/GetAllMoviesByIds', names)
      .pipe(catchError(this.errorHandler));
  }

  GetTotalSalesReport(data): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetTotalSalesReport', data)
      .pipe(catchError(this.errorHandler));
  }

  GetInventoryVarienceCostReport(data):Observable<any>{
    return this.http.post<any>(this.ApiBaseUrl + '/GetInventoryVarienceCostReportByLocationId', data)
      .pipe(catchError(this.errorHandler));
  }

  GetIncompleteOrderReportReportBeta(data: IncompleteOrderReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetIncompleteOrderReportReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }
  
  GetIncompleteOrderDetailReportForBeta(data: IncompleteOrderReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetIncompleteOrderDetailReportForBeta', data)
      .pipe(catchError(this.errorHandler));
  }
  
   GetTransactionReportBeta(data: TransactionReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetTransactionReportForBeta', data)
      .pipe(catchError(this.errorHandler));
  } 
  
  GetTipsReportBeta(data): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetTipsReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }
  
  GetSalesOrdersByShowIdAndSeatNumberBeta(data: any) {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetSalesOrdersByShowIdAndSeatNumberBeta', data)
  }
  GetSalesDiscountReportForBeta(data: SalesDiscountReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetSalesDiscountReportForReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }
  GetTotalSalesReportBeta(data): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetTotalSalesReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }

  GetDailySalesReportBeta(data: DailySalesReportInput): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetDailySalesReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }
  
  GetShiftReportBeta(data: ShiftReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetShiftReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }

  GetShiftSummaryReportBeta(data: ShiftSummaryReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetShiftSummaryReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }

  ShiftSummaryBeta(data: ShiftSummaryReportInput): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/ShiftSummaryBeta', data)
      .pipe(catchError(this.errorHandler));
  }

  GetSummaryReportBeta(data: SummaryReportInput): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetSummaryReportBeta', data)
      .pipe(catchError(this.errorHandler));
  }

}
