import { Injectable, Inject } from '@angular/core';
import { Concession, ConcessionCategory, ConcessionCustomize, ProcessInventorySoldOutRequest } from './concession';
import { Observable } from 'rxjs';
import {
    HttpClient, HttpBackend,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class ConcessionService extends ApiService {
  ApiBaseUrl: string;
  BaseUri: string;
  Concessions: Concession[];
  httpBackedurl: HttpClient;
  prepApi;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private backend: HttpBackend) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/Concession';
    this.BaseUri = baseUrl;
    this.httpBackedurl = new HttpClient(backend);
    this.prepApi = baseUrl + 'api/PrepArea'
  }

  getConcessions(): Observable<Concession[]> {
    return this.http
      .get<Concession[]>(this.ApiBaseUrl + '/GetByLocationId')
      .pipe(catchError(this.errorHandler));
  }
  getConcessionsbyStatus(visible: number): Observable<Array<Concession>> {
    return this.http.get<Array<Concession>>(this.ApiBaseUrl + '/GetConcessionsbyStatus/' + visible)
      .pipe(catchError(this.errorHandler));
  }

  getPOSConcessionsByLocationId(): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + '/GetPOSConcessionByLocationId')
      .pipe(catchError(this.errorHandler));
  }

  getConcessionByID(id: string): Observable<Concession> {
    return this.http
      .get<Concession>(this.ApiBaseUrl + '/' + id)
      .pipe(catchError(this.errorHandler));
  }

  getKioskConcessionByLocationId(): Observable<any> {
    return this.httpBackedurl
      .get<any>(this.ApiBaseUrl + '/GetKioskConcessionByLocationId')
      .pipe(catchError(this.errorHandler));
  }
  getConcessionCategory(): Observable<ConcessionCategory[]> {
    return this.httpBackedurl
      .get<any>(this.ApiBaseUrl + '/GetAllConcessionCategory')
      .pipe(catchError(this.errorHandler));
  }
  addConcession(NewConcession: Concession): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl, NewConcession)
      .pipe(catchError(this.errorHandler));
  }

  editConcession(EditConcession: Concession): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + '/' + EditConcession.ConcessionId, EditConcession)
      .pipe(catchError(this.errorHandler));
  }

  setVisible(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }

  setParentVisible(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/SetParentConcessionVisible/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }


  getPrepDetails() : Observable<any>{
    return this.http.get<any>(this.prepApi + '/GetPrepAreas').pipe(catchError(this.errorHandler));
  }
  getPrepAreaOpenClose(): Observable<any> {
    return this.http.get<any>(this.prepApi + '/GetOpenClosedPrepAreaByLocationId').pipe(catchError(this.errorHandler));
  }
  addPrepAreaOpenclose(prepAreopenclose: any): Observable<any> {
    return this.http
      .post(this.prepApi + '/AddPrepAreaOpenClose', prepAreopenclose)
      .pipe(catchError(this.errorHandler));
  }
  checkconcessioninventoryStatus(concessionId:string): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/CheckConcessionInventoryStatus/' + concessionId).pipe(catchError(this.errorHandler));
  }
  checkParentConcessionInventoryStatus(concessionId: string): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/CheckParentConcessionInventoryStatus/' + concessionId).pipe(catchError(this.errorHandler));
  }
  checkConcessionUsedInPriceCard(concessionId: string): Observable<boolean> {
    return this.http.get<boolean>(this.ApiBaseUrl + '/CheckConcessionUsedInPriceCard/' + concessionId).pipe(catchError(this.errorHandler));
  }
  setInActiveConcessionWithPriceCard(ID: string): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/SetInActiveConcessionWithPriceCard/' + ID , null)
      .pipe(catchError(this.errorHandler));
  }
  getConcessionsCategory(): Observable<ConcessionCategory[]> {
    return this.http
      .get<ConcessionCategory[]>(this.ApiBaseUrl + '/GetConcessioncategory')
      .pipe(catchError(this.errorHandler));
  }
  getConcessionCategoryValues(): Observable<string[]> {
    return this.http
      .get<string[]>(this.ApiBaseUrl + '/GetConcessionCategoryValues')
      .pipe(catchError(this.errorHandler));
  }
  addConcessionCategory(NewConcessionCategory: ConcessionCategory): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/AddConcessioncategory', NewConcessionCategory)
      .pipe(catchError(this.errorHandler));
  }

  getConcessionCategoryByID(id: string): Observable<ConcessionCategory> {
    return this.http
      .get<ConcessionCategory>(this.ApiBaseUrl + '/GetConcessioncategory/' + id)
      .pipe(catchError(this.errorHandler));
  }


  editConcessionCategory(EditConcession: ConcessionCategory,categoryFlag,subcategoryFlag): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + '/UpdateConcessionCategory/' + EditConcession.FoodCategoryId+'/'+categoryFlag+'/'+subcategoryFlag, EditConcession)
      .pipe(catchError(this.errorHandler));
  }
  
  CheckConcessionExistForId(concessionId:string): Observable<boolean> {
    return this.http.get<boolean>(this.ApiBaseUrl + '/CheckConcessionExistForId/' + concessionId).pipe(catchError(this.errorHandler));
  }
  setVisibleForCategory(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/ConcessionCategoryVisibleUpdate/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }

  getConcessionCustomize(concessionId: string): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + '/GetConcessionCustomize/' + concessionId)
      .pipe(catchError(this.errorHandler));
  }

  saveConcessionCustomize(customize: ConcessionCustomize): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + '/SaveConcessionCustomize/' + customize.ConcessionId, customize)
      .pipe(catchError(this.errorHandler));
  }

  getFoodsByName(name: string): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "/GetFoodsByName/" + name)
      .pipe(catchError(this.errorHandler));
  }

  getInventoryByName(name: string): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "/GetInventoryByName/" + name)
      .pipe(catchError(this.errorHandler));
  }

  getAllConcessionsbyStatus(visible: number): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/GetAllConcessionsbyStatus/' + visible)
      .pipe(catchError(this.errorHandler));
  }

  // processSoldOutInventoryForConcession(
  // ): Observable<boolean> {
  //   return this.http
  //     .get<boolean>(
  //       `${this.ApiBaseUrl}/ProcessSoldOutInventoryForConcession/`
  //     )
  //     .pipe(catchError(this.errorHandler));
  // }


  processSoldOutInventoryForConcession(processInvenorySoldOutReq: ProcessInventorySoldOutRequest): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/ProcessSoldOutInventoryForConcession', processInvenorySoldOutReq)
      .pipe(catchError(this.errorHandler));
  }

}
